import { useMutation, useQuery } from '@tanstack/react-query';
import { Loading, Navbar } from 'components';
import { queryKeys, routes, storageKeys } from '../constants';
import { Outlet, useMatch, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef } from 'react';
import {
  getActivityAreas,
  getCurrentUser,
  getFilterOptions,
  getListPendingPayment,
  getPoints,
  sendActive,
} from 'api';
import { useAppStore, usePointStore, useUserStore } from 'store';
import { TFilterOptions, TPoints, TSendActive, TUser } from 'types';
import Pubnub from 'pubnub';
import { PubNubProvider } from 'pubnub-react';
import { getMessagingToken } from 'configs';
import { useMessage } from 'hooks';
import OneSignal from 'react-onesignal';

function Layout() {
  // Hooks
  const isInitial = useRef(true);
  const { isAuthenticated, setUser, setClientId, subscriptionId } =
    useUserStore();
  const { setFilterOptions, setPaymentPending, setActivityAreas } =
    useAppStore();
  const { setPoints } = usePointStore();
  const isInChat = useMatch(routes.CONVERSATION);
  const navigate = useNavigate();
  const { refetch: refetchUnreadCount } = useMessage(true);

  // Queries
  const { data, isLoading } = useQuery<TUser>({
    queryKey: [queryKeys.CURRENT_USER],
    queryFn: getCurrentUser,
    networkMode: 'always',
    refetchOnMount: false,
    enabled: isAuthenticated,
  });

  const { data: points } = useQuery<TPoints>({
    queryKey: [queryKeys.POINTS],
    queryFn: getPoints,
    enabled: isAuthenticated,
  });

  const { data: filterOptions } = useQuery<TFilterOptions>({
    refetchOnMount: false,
    queryKey: [queryKeys.FILTER_OPTIONS],
    queryFn: getFilterOptions,
  });

  const { data: listPendingPayment, dataUpdatedAt } = useQuery<string[]>({
    queryKey: [queryKeys.LIST_PENDING_PAYMENT],
    queryFn: getListPendingPayment,
    enabled: isAuthenticated,
  });

  const { data: activityAreas } = useQuery({
    queryKey: [queryKeys.ACTIVITY_AREA],
    queryFn: () => getActivityAreas(),
    refetchOnMount: false,
  });

  // Mutations
  const { mutateAsync: mutateSendActive } = useMutation({
    mutationFn: (body: TSendActive) => {
      return sendActive(body);
    },
  });

  //Effects
  useEffect(() => {
    if (data) {
      setUser(data);
      data.hasPendingReview && navigate(routes.REVIEW_GIRL);
    }
  }, [data, navigate, setUser]);

  useEffect(() => {
    if (!isInitial.current) {
      refetchUnreadCount();
    } else {
      isInitial.current = false;
    }

    return () => {};
  }, [isAuthenticated, refetchUnreadCount]);

  useEffect(() => {
    if (filterOptions) {
      setFilterOptions(filterOptions);
    }
  }, [filterOptions, setFilterOptions]);

  useEffect(() => {
    if (points) {
      setPoints(points);
    }
  }, [points, setPoints]);

  useEffect(() => {
    if (listPendingPayment?.length) {
      setPaymentPending({
        ids: listPendingPayment,
        lastFetchingTime: dataUpdatedAt,
      });
    }
  }, [dataUpdatedAt, listPendingPayment, setPaymentPending]);

  useEffect(() => {
    if (activityAreas?.length) {
      setActivityAreas(activityAreas);
    }
  }, [activityAreas, setActivityAreas]);

  // Memo, callbacks
  const clientId = React.useMemo(() => {
    const clientId =
      localStorage.getItem(storageKeys.CLIENT_ID) || `${Date.now()}`;
    localStorage.setItem(storageKeys.CLIENT_ID, clientId);
    setClientId(clientId);
    return clientId;
  }, [setClientId]);

  const pubnub = React.useMemo(() => {
    return new Pubnub({
      publishKey: process.env.REACT_APP_PUBNUB_PUBLISH_KEY,
      subscribeKey: process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY || '',
      uuid: data?.uuid || clientId,
    });
  }, [data?.uuid, clientId]);

  React.useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    (async () => {
      if (!interval && mutateSendActive && data?.id) {
        const token = await getMessagingToken();
        if (isAuthenticated) {
          await mutateSendActive({
            clientId,
            fcmToken: token || '',
            subscriptionId:
              subscriptionId || OneSignal?.User?.PushSubscription?.id || null,
          });
          // interval = setInterval(async () => {
          //   // send active to server
          //   await mutateSendActive({ clientId, fcmToken: token });
          // }, INTERVAL_SEND_ACTIVE);
        }
      }
    })();
    return () => {
      if (interval) clearInterval(interval);
    };
  }, [clientId, data?.id, isAuthenticated, mutateSendActive, subscriptionId]);

  useEffect(() => {
    return () => {
      if (
        !!localStorage.getItem(storageKeys.REQUIRE_SIGNUP) &&
        !isAuthenticated &&
        ![routes.SIGNUP, routes.LOGIN].includes(window.location.pathname)
      ) {
        navigate(routes.SIGNUP);
      }
    };
  }, [navigate, isAuthenticated]);

  return isLoading ? (
    <Loading size="md" fullScreen />
  ) : (
    <div className="h-full w-full overflow-hidden">
      {pubnub && (
        <PubNubProvider client={pubnub}>
          <div className={`h-full ${!isInChat ? 'pb-[68px]' : ''}`}>
            <Outlet />
          </div>
          {!isInChat && <Navbar />}
        </PubNubProvider>
      )}
    </div>
  );
}

export default Layout;
