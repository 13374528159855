import {
  Button,
  IconButton,
  Modal,
  PhoneInput,
  Select,
  Text,
  TextField,
} from 'components';
import Verify from 'components/Verify';
import {
  SESSION_KEYS,
  TEXT_STRING,
  routes,
  storageKeys,
} from '../../constants';
import { useAuth, useToast } from 'hooks';
import React, { useEffect } from 'react';
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { TPhoneInput, TSelectOption, TTextFieldChangeEvent } from 'types';
import {
  convertFormatAmount,
  getFormattedAmount,
  validateEmail,
  validatePhone,
} from 'utils';
import { Country } from 'react-phone-number-input';
import { useAppStore, useChatStore, useOrderStore, useUserStore } from 'store';
import Banner from '../../assets/images/registerBanner.png';
import { acsTracking } from 'utils';

const loginStr = TEXT_STRING.LOGIN;
const commonStr = TEXT_STRING.COMMON;
const introducingStr = TEXT_STRING.INTRODUCING;

const validateString = (value: string) => !value || !!value.trim();

enum EFieldRegister {
  AREA = 'areaId',
  NAME = 'nickname',
  PHONE = 'phone',
  EMAIL = 'email',
  AGE = 'age',
  INCOME = 'income',
}

const fields = [
  {
    name: EFieldRegister.AREA,
    label: TEXT_STRING.CAST_PAGE.ACTIVITY_AREA,
    placeholder: TEXT_STRING.COMMON.SELECT,
    validate: validateString,
  },
  {
    name: EFieldRegister.NAME,
    label: loginStr.NICK_NAME,
    type: 'text',
    placeholder: loginStr.PLACEHOLDER_NICK_NAME,
    validate: validateString,
  },
  {
    name: EFieldRegister.PHONE,
    label: loginStr.PHONE_NUMBER,
    type: 'number',
    placeholder: loginStr.PLACEHOLDER_PHONE,
  },
  {
    name: EFieldRegister.EMAIL,
    label: loginStr.EMAIL,
    type: 'text',
    placeholder: loginStr.PLACEHOLDER_EMAIL,
    validate: validateEmail,
  },
];

type TFormState = {
  [x: string]: string;
};

const countryCode = (process.env.REACT_APP_COUNTRY_CODE || 'JP') as Country;

const affiliate_pid = process.env.REACT_APP_AFFILIATE_PID || '';

function Signup() {
  // State
  const [inVerify, setInVerify] = React.useState<boolean>(false);
  const [formState, setFormState] = React.useState<TFormState>({
    nickname: '',
    email: '',
    areaId: '',
    age: '',
    income: '',
  });
  const [phoneState, setPhoneState] = React.useState<TPhoneInput>({
    countryCode,
    number: '',
  });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [invitationId, setInvitationId] = React.useState<string | null>();

  // Hooks
  const { sendOTP, verifyOTP, verifyPhone, signup } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const { toastError } = useToast();
  const [searchParams] = useSearchParams();

  const from = React.useMemo(
    () => location.state?.from?.pathname || '/',
    [location]
  );

  // Store
  const { isAuthenticated } = useUserStore();
  const { redirectOrderStep } = useOrderStore();
  const { removeLeveragePending } = useChatStore();
  const { activityAreas } = useAppStore();

  const options = React.useMemo<TSelectOption[]>(
    () =>
      activityAreas
        ?.filter((item) => !item.isOtherArea)
        ?.map((item) => ({
          name: item.name,
          value: `${item.id}`,
        })) || [],
    [activityAreas]
  );

  const onChangeField = React.useCallback(
    (name: string, value: string) => {
      setFormState((prev: TFormState) => ({ ...prev, [name]: value }));
    },
    [setFormState]
  );

  const onChangeNumberField = React.useCallback((e: TTextFieldChangeEvent) => {
    const { name, value } = e.target;
    if (+value || +value === 0) {
      setFormState((prev) => ({ ...prev, [name]: value }));
    }
  }, []);

  const handleFocus = React.useCallback((e: TTextFieldChangeEvent) => {
    const { name, value } = e.target;
    if (value)
      setFormState((prev) => ({
        ...prev,
        [name]: `${convertFormatAmount(value)}`,
      }));
  }, []);

  const handleBlur = React.useCallback((e: TTextFieldChangeEvent) => {
    const { name, value } = e.target;
    if (value) {
      setFormState((prev) => ({ ...prev, [name]: getFormattedAmount(+value) }));
    }
  }, []);

  const onBack = React.useCallback(() => {
    if (!!location.state?.from) {
      navigate(-1);
    } else navigate(routes.SEARCH);
  }, [navigate, location.state]);

  const onCancel = React.useCallback(() => {
    removeLeveragePending();
    onBack();
  }, [removeLeveragePending, onBack]);

  const onSignup = React.useCallback(async () => {
    if (
      fields.some(
        ({ name, validate }) =>
          name !== 'phone' &&
          (!formState[name] || (validate && !validate(formState[name])))
      ) ||
      !validatePhone(phoneState)
    ) {
      return;
    }

    if (phoneState.number) {
      try {
        setLoading(true);
        await verifyPhone(phoneState.number, false);
        await sendOTP(phoneState.number);
        setInVerify(true);
      } catch (error) {
        toastError(error as Error, { position: 'top-center' });
      } finally {
        setLoading(false);
      }
    }
  }, [phoneState, formState, verifyPhone, sendOTP, toastError]);

  const onVerify = React.useCallback(
    async (otp: string) => {
      try {
        setLoading(true);
        const token = await verifyOTP(otp);
        const affiliate_cid = localStorage.getItem(`CL_${affiliate_pid}`);
        if (token)
          await signup(
            {
              name: formState.nickname,
              email: formState.email,
              areaId: +formState.areaId,
              phone: phoneState.number || '',
              clientId: localStorage.getItem(storageKeys.CLIENT_ID) || '',
              age: convertFormatAmount(formState.age),
              income: convertFormatAmount(formState.income),
              inviteId: invitationId,
              affiliateInfo: {
                affiliate_pid: affiliate_pid,
                affiliate_cid: affiliate_cid,
                affiliate_mid: localStorage.getItem('mid'),
                affiliater_id: localStorage.getItem('affliater_id'),
              },
            },
            token
          );
        if (affiliate_cid) acsTracking(affiliate_pid);

        onBack();
        redirectOrderStep();
      } catch (error) {
        toastError(error as Error, { position: 'top-center' });
      } finally {
        setLoading(false);
      }
    },
    [
      verifyOTP,
      signup,
      formState,
      phoneState.number,
      redirectOrderStep,
      toastError,
      invitationId,
      onBack,
    ]
  );

  const onGoToLogin = React.useCallback(() => {
    navigate(routes.LOGIN, {
      replace: true,
    });
  }, [navigate]);

  useEffect(() => {
    const paramId =
      searchParams.get('invite') ||
      sessionStorage.getItem(SESSION_KEYS.INVITE_ID);
    if (paramId) {
      sessionStorage.setItem(SESSION_KEYS.INVITE_ID, paramId);
    }
    setInvitationId(paramId);
    return () => {};
  }, [searchParams]);

  if (isAuthenticated) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    // return <h1>Protected Route</h1>;
    return <Navigate to={from} state={{ from: location }} replace />;
  }

  return (
    <div className="absolute top-0 bg-black w-full h-full z-10">
      <Modal
        open={true}
        onClose={() => {}}
        className={`px-5 py-6 mx-5 bg-white w-full`}
      >
        {inVerify ? (
          <Verify
            phone={phoneState.number || ''}
            verifyOTP={onVerify}
            resendOTP={() => sendOTP(phoneState.number || '')}
            loading={loading}
          />
        ) : (
          <>
            <IconButton
              icon="close"
              transparent
              className="fixed top-0 right-0 z-50 m-4"
              onClick={onCancel}
            />
            <div className="bg-white flex flex-col gap-5">
              {invitationId ? (
                <div className={`mt-8`}>
                  <img src={Banner} alt="introducing-friend" />
                  <Text textColor="text-neutral" center fontSize={13}>
                    {introducingStr.tecoAssetsNote}
                  </Text>
                </div>
              ) : (
                <Text center bold className="mt-8 mb-2">
                  {loginStr.MESSAGE_REGISTER}
                </Text>
              )}
              {fields.map((field, index) =>
                field.name === EFieldRegister.PHONE ? (
                  <PhoneInput
                    key={index}
                    name={field.name}
                    onChange={setPhoneState}
                    value={phoneState}
                    label={field.label}
                    type={field.type}
                    placeholder={field.placeholder}
                    error={!validatePhone(phoneState)}
                    require
                    inputClass="py-2.5"
                  />
                ) : field.name === EFieldRegister.AREA ? (
                  <Select
                    key={index}
                    label={field.label}
                    name={field.name}
                    value={formState[field.name]}
                    onChange={(value) => onChangeField(field.name, value)}
                    options={options}
                    require
                    className="py-2.5"
                    bold
                  />
                ) : [EFieldRegister.AGE, EFieldRegister.INCOME].includes(
                    field.name
                  ) ? (
                  <TextField
                    key={index}
                    name={field.name}
                    onChange={onChangeNumberField}
                    value={formState[field.name]}
                    label={field.label}
                    type={field.type}
                    placeholder={field.placeholder}
                    error={
                      field.validate && !field.validate(formState[field.name])
                    }
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    require
                    bold
                  />
                ) : (
                  <TextField
                    key={index}
                    name={field.name}
                    onChange={(e) => onChangeField(field.name, e.target.value)}
                    value={formState[field.name]}
                    label={field.label}
                    type={field.type}
                    placeholder={field.placeholder}
                    error={
                      field.validate && !field.validate(formState[field.name])
                    }
                    require
                    bold
                  />
                )
              )}
              <Text>
                {loginStr.GO_LOGIN}{' '}
                <span
                  className="text-primary cursor-pointer"
                  onClick={onGoToLogin}
                >
                  {loginStr.HERE}
                </span>
              </Text>
            </div>
            <div className="mt-16px">
              <Button
                onClick={onSignup}
                block
                className=""
                disabled={loading}
                loading={loading}
              >
                {loginStr.REGISTRATION}
              </Button>
              <Button
                onClick={onCancel}
                block
                textColor="text-primary"
                bgColor="bg-white"
                className="py-0 mt-4"
                disabled={loading}
              >
                {commonStr.CANCEL}
              </Button>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
}

export default Signup;
